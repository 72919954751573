<template>
  <v-card outlined>
    <v-toolbar color="titleBar" dark flat dense>
      <v-toolbar-title>
        {{ $t("ACS device information") }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            @click="dialog = true"
            :disabled="acsIsExpired || dialog == true"
            icon
          >
            <v-icon>mdi-lock-open</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("open GUI") }}</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            @click="getDeviceInfo"
            :disabled="loading"
            :loading="loading"
            icon
          >
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("refresh device info") }}</span>
      </v-tooltip>
    </v-toolbar>
    <v-dialog v-model="dialog" width="80%" scrollable persistent>
      <open-gui v-if="dialog" :product-id="productId" @close="dialog = false" />
    </v-dialog>
    <v-card-text>
      <v-simple-table class="ma-4" dense>
        <template v-slot:default>
          <tbody v-if="info == null && !loading">
            <tr>
              <td colspan="2">
                {{ $t("acsNoDataMsg") }}
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="info != null">
            <tr>
              <td colspan="2">
                <span :class="acsIsExpired ? 'red--text' : 'green--text'">
                  {{
                    acsIsExpired
                      ? $t("acsIsExpireMsg")
                      : $t("acsIsNotExpireMsg")
                  }}
                </span>
              </td>
            </tr>
            <tr v-if="info.bw_down != null">
              <td>{{ $t("bandwidth down") }}:</td>
              <td>{{ info.bw_down }}</td>
            </tr>
            <tr v-if="info.bw_up != null">
              <td>{{ $t("bandwidth up") }}:</td>
              <td>{{ info.bw_up }}</td>
            </tr>
            <tr>
              <td>{{ $t("CPE ID") }}:</td>
              <td>{{ info.cpeid }}</td>
            </tr>
            <tr>
              <td>{{ $t("hardware version") }}:</td>
              <td>{{ info.hardware_version }}</td>
            </tr>
            <tr>
              <td>{{ $t("inform interval") }}:</td>
              <td>{{ info.inform_interval }}</td>
            </tr>
            <tr>
              <td>{{ $t("current IP address") }}:</td>
              <td>{{ info.ip }}</td>
            </tr>
            <tr v-if="info.last_msg != null">
              <td>{{ $t("last message seen") }}:</td>
              <td :class="acsIsExpired ? 'red--text' : 'green--text'">
                {{ formatDateTime(info.last_msg) }}
              </td>
            </tr>
            <tr>
              <td>{{ $t("manufacturer") }}:</td>
              <td>{{ info.manufacturer }}</td>
            </tr>
            <tr>
              <td>{{ $t("manufacturer OUI") }}:</td>
              <td>{{ info.manufacturer_oui }}</td>
            </tr>
            <tr>
              <td>{{ $t("router type") }}:</td>
              <td>{{ info.router_type }}</td>
            </tr>
            <tr>
              <td>{{ $t("software version") }}:</td>
              <td>{{ info.software_version }}</td>
            </tr>
            <tr>
              <td>{{ $t("WAN access type") }}:</td>
              <td>{{ info.wan_access_type }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import formatDateTime from "@/utils/mixins/formatDateTime";
import openGui from "./OpenGui";

export default {
  name: "InternetDeviceInfo",
  components: {
    openGui,
  },
  mixins: [formatDateTime],
  props: {
    productId: {
      type: [String, Number],
      required: true,
    },
  },
  data: () => ({
    info: null,
    loading: false,
    dialog: false,
  }),
  watch: {
    productId: {
      immediate: true,
      handler() {
        this.getDeviceInfo();
      },
    },
  },
  computed: {
    acsIsExpired() {
      if (this.info != null && this.info.last_msg)
        return this.$moment(new Date())
          .subtract(this.info.inform_interval || 86400, "seconds")
          .isAfter(this.info.last_msg);
      return true;
    },
  },
  methods: {
    getDeviceInfo() {
      if (this.productId) {
        var that = this;
        this.loading = true;
        this.$http
          .get("services/internet/" + this.productId + "/deviceinfo", {
            disableDefaultErrorHandler: true,
          })
          .then((response) => {
            that.info = response.data;
          })
          .catch((err) => {})
          .finally(function () {
            that.loading = false;
          });
      }
    },
  },
};
</script>

<i18n>
{
  "en": {
    "ACS device information": "ACS Device Information",
    "bandwidth down": "Bandwidth down",
    "bandwidth up": "Bandwidth up",
    "CPE ID": "CPE ID",
    "hardware version": "Hardware version",
    "inform interval": "Inform interval",
    "current IP address": "Current IP address",
    "last message seen": "Last message seen",
    "manufacturer": "Manufacturer",
    "manufacturer OUI": "Manufacturer OUI",
    "router type": "Router type",
    "software version": "Software version",
    "WAN access type": "WAN access type",
    "open GUI": "open GUI",
    "refresh device info": "refresh device info",
    "acsIsExpireMsg": "The ACS system does not appear to have current information.",
    "acsIsNotExpireMsg": "The ACS system appears to have up-to-date information.",
    "acsNoDataMsg": "No ACS information available."
  }, 
  "de": {
    "ACS device information": "ACS Geräte Information",
    "bandwidth down": "Bandbreite Down",
    "bandwidth up": "Bandbreite Up",
    "CPE ID": "CPE ID",
    "hardware version": "Hardware Version",
    "inform interval": "Informierunginterval",
    "current IP address": "aktuelle IP Adresse",
    "last message seen": "letzte Nachricht gesehen",
    "manufacturer": "Hersteller",
    "manufacturer OUI": "Hersteller OUI",
    "router type": "Routertyp",
    "software version": "Software Version",
    "WAN access type": "WAN Zugriffstyp",
    "open GUI": "öffne GUI",
    "refresh device info": "aktualisiere Geräte Information",
    "acsIsExpireMsg": "Das ACS System scheint nicht, über aktuelle Informationen zu verfügen.",
    "acsIsNotExpireMsg": "Das ACS System scheint, über aktuelle Informationen zu verfügen.",
    "acsNoDataMsg": "Keine ACS Informationen verfügbar."
  }
}
</i18n>
