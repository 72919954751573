<template>
  <v-card>
    <v-toolbar color="titleBar" dark flat dense>
      <v-toolbar-title>
        {{ $t("ACS open GUI access") }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            :disabled="loading"
            :loading="loading"
            @click="getOpenGui()"
            icon
          >
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("refresh data") }}</span>
      </v-tooltip>
    </v-toolbar>
    <v-progress-linear :active="loading" indeterminate />
    <v-card-text>
      <v-simple-table class="ma-4">
        <template v-slot:default>
          <tbody>
            <tr>
              <td>{{ $t("user") }}:</td>
              <td v-show="!loading">
                <v-text-field
                  id="guiUsername"
                  v-model="info.user"
                  append-icon="mdi-content-copy"
                  @click:append="copyUsername"
                  dense
                  readonly
                />
              </td>
            </tr>
            <tr>
              <td>{{ $t("password") }}:</td>
              <td v-show="!loading">
                <v-text-field
                  id="guiPassword"
                  v-model="info.password"
                  append-icon="mdi-content-copy"
                  @click:append="copyPassword"
                  dense
                  readonly
                />
              </td>
            </tr>
            <tr v-if="url">
              <td>{{ $t("link") }}:</td>
              <td v-show="!loading">
                <v-chip :href="url" target="_blank" color="primary" link>{{
                  url
                }}</v-chip>
              </td>
            </tr>
            <tr>
              <td>{{ $t("auto close") }}:</td>
              <td v-show="!loading">
                <boolean-value v-model="info.auto_close" />
              </td>
            </tr>
            <tr>
              <td>{{ $t("cached") }}:</td>
              <td v-show="!loading"><boolean-value v-model="info.cached" /></td>
            </tr>
            <tr v-if="info.cached">
              <td>{{ $t("age") }}:</td>
              <td v-show="!loading">{{ info.age }} {{ $t("minutes") }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn :disabled="loading" @click="$emit('close')" text>
        {{ $t("cancel") }}
      </v-btn>
      <v-btn
        :disabled="loading"
        :loading="loading"
        @click="getOpenGui(false)"
        text
      >
        {{ $t("reopen") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import formatDateTime from "@/utils/mixins/formatDateTime";
import BooleanValue from "@/components/basics/BooleanValue";
import copyToClipboard from "@/utils/mixins/copyToClipboard";

export default {
  name: "OpenGui",
  components: {
    BooleanValue,
  },
  mixins: [formatDateTime, copyToClipboard],
  props: {
    productId: {
      type: [String, Number],
      required: true,
    },
  },
  data: () => ({
    info: {},
    loading: false,
  }),
  watch: {
    productId: {
      immediate: true,
      handler() {
        this.getOpenGui();
      },
    },
  },
  computed: {
    url() {
      if (
        this.info.ip &&
        this.info.port &&
        this.info.user &&
        this.info.password
      )
        return "https://" + this.info.ip + ":" + this.info.port;
      else return null;
    },
  },
  methods: {
    copyUsername() {
      this.copyToClipboard("guiUsername", this.$t("username copied"));
    },
    copyPassword() {
      this.copyToClipboard("guiPassword", this.$t("password copied"));
    },
    getOpenGui(cached = true) {
      if (this.productId) {
        var that = this;
        this.loading = true;
        this.$http
          .get("services/internet/" + this.productId + "/opengui", {
            disableDefaultErrorHandler: true,
            params: { cached: cached },
          })
          .then((response) => {
            that.info = response.data;
          })
          .catch((err) => {
            that.$snotify.error(err.message, {
              timeout: 2000,
              showProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
            });
          })
          .finally(function() {
            that.loading = false;
          });
      }
    },
  },
};
</script>

<i18n>
{
  "en": {
    "ACS open GUI access": "ACS open GUI access",
    "refresh data": "refresh data",
    "auto close": "Auto close",
    "open again": "open again",
    "CWMP ID": "CWMP ID",
    "IP address and port": "IP address and port",
    "user": "User",
    "password": "Password",
    "cached": "Cached",
    "age": "Age",
    "minutes": "Minutes",
    "cancel": "Cancel",
    "reopen": "Reopen",
    "link": "Link",
    "username copied": "username copied",
    "password copied": "password copied"
  }, 
  "de": {
    "ACS open GUI access": "ACS GUI Zugriff öffnen",
    "refresh data": "Daten aktualisieren",
    "auto close": "Auto Close",
    "open again": "erneut öffnen",
    "CWMP ID": "CWMP ID",
    "IP address and port": "IP Adresse und Port",
    "user": "Benutzer",
    "password": "Passwort",
    "cached": "Zwischengespeichert",
    "age": "Alter",
    "minutes": "Minuten",
    "cancel": "Abbrechen",
    "reopen": "Erneut öffnen",
    "link": "Verweis",
    "username copied": "Benutzername kopiert",
    "password copied": "Passwort kopiert"
  }
}
</i18n>
