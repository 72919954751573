<template>
  <v-card>
    <v-toolbar color="titleBar" dark flat dense>
      <v-toolbar-title>
        {{ $t("Metronet up/downgrade") }}
      </v-toolbar-title>
    </v-toolbar>
    <v-progress-linear :active="loading" indeterminate />
    <v-card-text class="mt-4">
      <v-stepper v-model="step" alt-labels>
        <v-stepper-header>
          <v-stepper-step :complete="step > 1" step="1">
            {{ $t("profile") }}
          </v-stepper-step>
          <v-divider></v-divider>

          <v-stepper-step :complete="step > 2" step="2">
            {{ $t("options") }}
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <v-card elevation="0" color="transparent">
              <validation-observer
                v-slot="{ invalid }"
                ref="opsStep1"
                :disabled="step !== 1"
              >
                <v-card-text class="mb-12" v-if="step === 1">
                  <profiles-metronet :reset-profile-idx="false" />
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn @click="$emit('upgradeCanceled')" text>
                    {{ $t("cancel") }}
                  </v-btn>
                  <v-btn
                    color="primary"
                    @click="step = step + 1"
                    :disabled="invalid || loading || profile == null"
                    text
                  >
                    {{ $t("continue") }}
                  </v-btn>
                </v-card-actions>
              </validation-observer>
            </v-card>
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-card elevation="0" color="transparent">
              <validation-observer
                v-slot="{ invalid }"
                ref="opsStep2"
                :disabled="step !== 2"
              >
                <v-card-text class="mb-12" v-if="step === 2">
                  <order-summary class="mb-4" />
                  <create-order :service="metronetService" upgrade />
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn @click="$emit('upgradeCanceled')" text>
                    {{ $t("cancel") }}
                  </v-btn>
                  <v-btn @click="step = step - 1" :disabled="ordering" text>
                    {{ $t("back") }}
                  </v-btn>
                  <v-btn
                    color="primary"
                    @click="order"
                    :disabled="invalid || ordering"
                    :loading="ordering"
                    text
                  >
                    {{ $t("order") }}
                  </v-btn>
                </v-card-actions>
              </validation-observer>
            </v-card>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card-text>
  </v-card>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import ProfilesMetronet from "@/components/orders/internet/ProfilesMetronet";
import CreateOrder from "@/components/orders/internet/CreateOrder";
import OrderSummary from "@/components/orders/internet/OrderSummary";
import convertCheckerResults from "@/utils/convert/convertCheckerResults";
import showErrors from "@/utils/mixins/showErrors";

const { mapState, mapGetters, mapMutations, mapActions } =
  createNamespacedHelpers("inetOrder");

export default {
  name: "MetronetUpdate",
  mixins: [showErrors],
  components: {
    ProfilesMetronet,
    CreateOrder,
    OrderSummary,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    metronetProfile: {
      type: Object,
      required: true,
    },
    metronetService: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    ordering: false,
    step: 1,
  }),
  watch: {
    metronetService: {
      immediate: true,
      handler(value) {
        this.prepare();
        this.setOptions(this.metronetService.gfo_options);
      },
    },
  },
  computed: {
    ...mapState([
      "productDescr",
      "orderEmail",
      "orderNote",
      "slaEmail",
      "slaMobile",
      "options",
      "wishDate",
    ]),
    ...mapGetters(["profile", "profiles"]),
    provider() {
      // force recompute
      this.metronetService;

      switch (
        this.metronetService.gfo_network != null
          ? this.metronetService.gfo_network.id
          : 0
      ) {
        case 248:
          return "litex";
        default:
          return "alex";
      }
    },
    currentSlaEmail() {
      if (this.product != null) {
        var slas = this.product.variants.filter(function (v) {
          return v.tags.includes("sla");
        });
        if (slas.length > 0) {
          var attrs = slas[0].attributes.filter(function (a) {
            return a.attr_code == "SLA-MAIL";
          });
          if (attrs.length > 0) return attrs[0].attr_value;
        }
      }
      return "";
    },
    currentSlaMobile() {
      if (this.product != null) {
        var slas = this.product.variants.filter(function (v) {
          return v.tags.includes("sla");
        });
        if (slas.length > 0) {
          var attrs = slas[0].attributes.filter(function (a) {
            return a.attr_code == "SLA-MOBILE";
          });
          if (attrs.length > 0) return attrs[0].attr_value;
        }
      }
      return "";
    },
  },
  methods: {
    ...mapMutations([
      "setCheckIdx",
      "setResultIdx",
      "setServiceIdx",
      "setOto",
      "setPlug",
      "setOptions",
      "setProfileIdx",
      "setProductDescr",
      "setOrderEmail",
      "setOrderNote",
      "setSlaEmail",
      "setSlaMobile",
    ]),
    ...mapActions(["resetCheckResults", "resetOrderContact"]),
    prepare() {
      /* prepare ProfilesMetronet component
       * TODO: review later
       */
      this.step = 0;
      this.setCheckIdx(2); // OTO
      this.resetOrderContact();
      this.resetCheckResults([]);
      this.setOto(this.metronetService.oto || "");
      this.setPlug(this.metronetService.plug || "");
      this.setProductDescr(this.product.description || "");
      this.setOrderEmail(this.$store.getters.deliveryEmail || "");
      this.setOrderNote("");
      this.setSlaEmail(this.currentSlaEmail);
      this.setSlaMobile(this.currentSlaMobile);
      this.checkerQuery();
    },
    checkerQuery: function () {
      var that = this;
      this.loading = true;
      var params = {
        oto: this.metronetService.oto,
        plug: this.metronetService.plug,
        service_provider: this.provider,
      };
      this.$http
        .get("services/internet/check", { params: params })
        .then((response) => {
          that.resetCheckResults(convertCheckerResults(response.data));
          // select current profile
          var i = 0;
          that.profiles.forEach(function (profile) {
            if (profile.gfo_variant.id === that.metronetService.gfo_variant.id)
              that.setProfileIdx(i);
            i += 1;
          });
        })
        .catch((error) => {
          console.log(">>> error ", error);
          if (error.invalid()) {
            that.$store.commit("setSystemError", {
              msg: error.message,
              title: error.title,
            });
          }
        })
        .finally(function () {
          that.loading = false;
          that.step = 1;
        });
    },
    order: function () {
      var that = this;
      this.ordering = true;
      var url = null;
      var params = {
        options: this.options.map(function (opt) {
          return {
            id: opt.id,
            quantity: opt.disabled ? 0 : opt.quantity,
          };
        }),
        email: this.orderEmail,
        note: this.orderNote || null,
        sla_email: this.slaEmail,
        sla_mobile: this.slaMobile,
        product_description: this.productDescr || null,
        variant_id:
          this.profile.gfo_variant != null ? this.profile.gfo_variant.id : null,
        wishdate: this.wishDate,
      };

      this.$http
        .put("services/metronet/" + this.product.id, params)
        .then((response) => {
          that.$snotify.success(
            that.$i18n.t(
              "Your {worker} {subject} has been successfully commited.",
              {
                worker: response.data.worker_name,
                subject: response.data.subject,
              }
            )
          );
          that.$router.push({
            name: "tasks",
          });
          that.step = 1;
        })
        .catch((error) => {
          if (error.invalid()) {
            that.$refs.opsStep2.setErrors(error.data);
            that.showErrors(that.$refs.opsStep2.getUnresolvedErrors(error));
          } else
            that.$store.commit("setSystemError", {
              msg: error.message,
              title: error.title,
            });
        })
        .finally(function () {
          that.ordering = false;
        });
    },
  },
};
</script>

<i18n>
{
  "en": {
    "Metronet up/downgrade": "Metronet up/downgrade",
    "profile": "Profile",
    "options": "Options",
    "order": "Order",
    "wishdate": "Wishdate",
    "check": "Check",
    "back": "Back",
    "cancel": "Cancel",
    "continue": "Continue",
    "Your {worker} {subject} has been successfully commited.": "Your {worker} {subject} has been successfully commited."
  }, 
  "de": {
    "Metronet up/downgrade": "Metronet Up/Downgrade",
    "profile": "Profil",
    "options": "Optionen",
    "order": "Bestellung",
    "wishdate": "Wunschdatum",
    "check": "prüfen",
    "back": "zurück",
    "cancel": "Abbrechen",
    "continue": "weiter",
    "Your {worker} {subject} has been successfully commited.": "Ihre {worker} {subject} wurde erfolgreich übermittelt."
  }
}
</i18n>
