import store from "@/store";

export default {
  methods: {
    hasApiRole(role) {
      return store.state.session.apiRoles.includes(role.toUpperCase());
    },
  },
  computed: {
    hasOrderRole() {
      return this.hasApiRole('API-ORD');
    },
    hasProductRole() {
      return this.hasApiRole('API-PROD');
    },
    hasInvoiceRole() {
      return this.hasApiRole('API-INV');
    }
  }
};
