var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-card',{attrs:{"outlined":""}},[_c('v-toolbar',{attrs:{"color":"titleBar","dark":"","flat":"","dense":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t("status")))]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.loading,"loading":_vm.loading,"icon":""},on:{"click":_vm.getStatus}},on),[_c('v-icon',[_vm._v("mdi-reload")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("refresh status")))])])],1),_c('v-card-text',[_c('v-simple-table',{attrs:{"dense":""}},[_c('tbody',[_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t("active")))]),_c('td',[_c('boolean-value',{attrs:{"colored":""},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}})],1)]),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t("disabled")))]),_c('td',[_c('boolean-value',{attrs:{"colored":""},model:{value:(_vm.disabled),callback:function ($$v) {_vm.disabled=$$v},expression:"disabled"}})],1)])])])],1)],1),(_vm.staticIps.lenght > 0)?_c('v-card',{staticClass:"mt-2",attrs:{"outlined":""}},[_c('v-toolbar',{attrs:{"color":"titleBar","dark":"","flat":"","dense":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t("static IPs")))])],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.staticIpsHeaders,"items":_vm.staticIps,"loading":_vm.loading,"mobile-breakpoint":_vm.mbreakpoint,"sort-by":"address","hide-default-footer":_vm.staticIps.length <= 15,"footer-props":{
          showFirstLastPage: true,
          itemsPerPageOptions: [15, 50],
        }}})],1)],1):_vm._e(),(_vm.staticSubnets.lenght > 0)?_c('v-card',{staticClass:"mt-2",attrs:{"outlined":""}},[_c('v-toolbar',{attrs:{"color":"titleBar","dark":"","flat":"","dense":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t("static IP subnets")))])],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.staticSubnetsHeaders,"items":_vm.staticSubnets,"loading":_vm.loading,"mobile-breakpoint":_vm.mbreakpoint,"sort-by":"destination","hide-default-footer":_vm.staticSubnets.length <= 15,"footer-props":{
          showFirstLastPage: true,
          itemsPerPageOptions: [15, 50],
        }}})],1)],1):_vm._e(),(_vm.leases.length > 0)?_c('v-card',{staticClass:"mt-2",attrs:{"outlined":""}},[_c('v-toolbar',{attrs:{"color":"titleBar","dark":"","flat":"","dense":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t("IP leases")))])],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.leasesHeaders,"items":_vm.leases,"loading":_vm.loading,"mobile-breakpoint":_vm.mbreakpoint,"sort-by":"time_end","hide-default-footer":_vm.leases.length <= 15,"footer-props":{
          showFirstLastPage: true,
          itemsPerPageOptions: [15, 50],
        }},scopedSlots:_vm._u([{key:"item.active",fn:function({ item }){return [_c('boolean-value',{model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})]}},{key:"item.dynamic",fn:function({ item }){return [_c('boolean-value',{model:{value:(item.dynamic),callback:function ($$v) {_vm.$set(item, "dynamic", $$v)},expression:"item.dynamic"}})]}},{key:"item.time_start",fn:function({ item }){return [_c('div',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(item.time_start ? _vm.formatDateTime(item.time_start) : "")+" ")])]}},{key:"item.time_end",fn:function({ item }){return [_c('div',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(item.time_end ? _vm.formatDateTime(item.time_end) : "")+" ")])]}},{key:"item.time_last_request",fn:function({ item }){return [_c('div',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(item.time_last_request ? _vm.formatDateTime(item.time_last_request) : "")+" ")])]}}],null,false,563795601)})],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }