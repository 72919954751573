<template>
  
    <v-card :loading="loading">
      <v-toolbar color="titleBar" dark flat dense>
        <v-toolbar-title>
          {{ $t("IP setup") }}
          {{ ipSetup != null ? " - " + $t(ipSetup.provider) : "" }}
        </v-toolbar-title>

        <v-spacer />
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              @click="getSetup"
              :disabled="loadingIpSetup"
              :loading="loadingIpSetup"
              icon
            >
              <v-icon>mdi-reload</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("refresh") }}</span>
        </v-tooltip>
      </v-toolbar>

      <v-card-text>
        <v-card class="mt-4" v-if="items.length > 0" outlined>
          <validation-observer ref="obsDeprovision" v-slot="{ errors, invalid, validated }">
          <v-toolbar color="titleBar" dark flat dense>
            <v-toolbar-title>
              {{ $t("IP/subnet deprovision") }}
            </v-toolbar-title>
          </v-toolbar>
          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="items"
            :loading="loadingIpSetup"
            item-key="network"
            :mobile-breakpoint="mbreakpoint"
            :hide-default-footer="true"
            show-select
            single-select
          >
            <template v-slot:item.network="{ item }">
              {{ item.network }}
            </template>

            <template v-slot:item.mac="{ item }">
              {{ item.mac ? item.mac : "-" }}
            </template>

            <template v-slot:item.discount="{ item }">
              {{ item.discount ? formatNumber(item.discount, "%") : "-" }}
            </template>

            <template v-slot:item.via="{ item }">
              {{ item.via ? item.via : "-" }}
            </template>

            <template v-slot:item.termination_date="{ item }">
              {{
                item.termination_date
                  ? formatDateTime(item.termination_date)
                  : "-"
              }}
            </template>
          </v-data-table>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <date-picker
                  v-model="terminationDate"
                  name="termination_date"
                  :label="$t('termination date')"
                  :clearable="!isMobile"
                  today
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              :disabled="invalid || loading || selected.length == 0"
              :loading="loadingDeprovision"
              @click="deprovision"
              text
            >
              {{ $t("deprovision") }}
            </v-btn>
          </v-card-actions>
          </validation-observer>
        </v-card>

        <v-card outlined class="mt-4" v-if="possiblePrimaryIps.length > 1">
          <validation-observer ref="obsPrimaryIp" v-slot="{ errors, invalid, validated }">
          <v-toolbar color="titleBar" dark flat dense>
            <v-toolbar-title>
              {{ $t("primary IP") }}:
              {{ ipSetup != null ? ipSetup.primary_ip : "" }}
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <validation-provider
                  vid="ip"
                  :name="$t('new primary IP')"
                  :rules="possiblePrimaryIps.length > 0 ? 'required' : ''"
                  v-slot="{ errors, valid, dirty, classes }"
                >
                  <v-select
                    v-model="primaryIp"
                    :items="possiblePrimaryIps"
                    :label="$t('new primary IP')"
                    :loading="loadingSetPrimaryIp"
                    :disabled="loading || possiblePrimaryIps.length <= 1"
                    :clearable="!isMobile"
                  />
                </validation-provider>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              :disabled="invalid || loading || !primaryIpChanged"
              :loading="loadingSetPrimaryIp"
              @click="setPrimaryIp"
              text
            >
              {{ $t("change") }}
            </v-btn>
          </v-card-actions>
        </validation-observer>
        </v-card>

        <v-card outlined class="mt-4">
          <validation-observer ref="obsOrder" v-slot="{ errors, invalid, validated }">
          <v-toolbar color="titleBar" dark flat dense>
            <v-toolbar-title>
              {{ $t("IP/subnet order") }}
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <validation-provider
                  vid="prefix_length"
                  :name="$t('prefix length')"
                  rules="numeric|min_value:27|max_value:30"
                  v-slot="{ errors, valid, dirty, classes }"
                >
                  <v-select
                    v-model="prefixLength"
                    :items="availablePrefixLengths"
                    name="prefix_length"
                    :label="$t('prefix length')"
                    :error-messages="errors"
                    :success="dirty && valid"
                    :class="classes"
                    type="number"
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip>
                        {{
                          $t(
                            item.count == 1 ? "prefix-single" : "prefix-subnet",
                            { value: item.value, count: item.count }
                          )
                        }}
                      </v-chip>
                    </template>
                    <template v-slot:item="{ item, index }">
                      <v-chip>
                        {{
                          $t(
                            item.count == 1 ? "prefix-single" : "prefix-subnet",
                            { value: item.value, count: item.count }
                          )
                        }}
                      </v-chip>
                    </template>
                  </v-select>
                </validation-provider>
              </v-col>
            </v-row>

            <v-row v-if="prefixLength != null">
              <v-col cols="12">
                <validation-provider
                  vid="customer.ripeNetname"
                  :name="$t('RIPE netname')"
                  rules="required|max:50|alpha_dash"
                  v-slot="{ errors, valid, dirty, classes }"
                >
                  <v-text-field
                    v-model="ripeNetname"
                    name="customer.ripeNetname"
                    :label="$t('RIPE netname')"
                    :hint="$t('network name for RIPE inetnum object')"
                    :error-messages="errors"
                    :success="dirty && valid"
                    :clearable="!isMobile"
                    :class="classes"
                    persistent-hint
                  />
                </validation-provider>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              :disabled="invalid || loading"
              :loading="loadingCreate"
              @click="orderNewIps"
              text
            >
              {{ $t("order") }}
            </v-btn>
          </v-card-actions>
          </validation-observer>
        </v-card>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="$emit('close')" :disabled="loading" text>
          {{ $t("cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </validation-observer>
</template>

<script>
import SwissAddressAutocomplete from "@/components/basics/SwissAddressAutocomplete";
import DatePicker from "@/components/basics/DatePicker";
import showErrors from "@/utils/mixins/showErrors";
import isMobile from "@/utils/mixins/isMobile";
import formatNumber from "@/utils/mixins/formatNumber";
import formatDateTime from "@/utils/mixins/formatDateTime";
import { roundArrow } from "tippy.js";

export default {
  name: "IpSetup",
  mixins: [formatNumber, formatDateTime, showErrors, isMobile],
  components: {
    SwissAddressAutocomplete,
    DatePicker,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    ipSetup: null,
    selected: [],
    loadingIpSetup: false,
    loadingDeprovision: false,
    loadingSetPrimaryIp: false,
    loadingCreate: false,
    terminationDate: null,
    primaryIp: null,
    prefixLength: null,
    ripeNetname: null,
    availablePrefixLengths: [
      { text: "single-ip", value: null, count: 1 },
      { text: "ip-subnet", value: 30, count: 4 },
      { text: "ip-subnet", value: 29, count: 8 },
      { text: "ip-subnet", value: 28, count: 16 },
      { text: "ip-subnet", value: 27, count: 32 },
    ],
  }),
  computed: {
    headers() {
      this.items;
      return [
        {
          text: this.$i18n.t("IP/subnet"),
          value: "network",
          sortable: false,
        },
        {
          text: this.$i18n.t("via"),
          value: "via",
          sortable: false,
          hidden: !this.hasVia,
        },
        {
          text: this.$i18n.t("MAC"),
          value: "mac",
          sortable: false,
          hidden: !this.hasMAC,
        },
        { text: this.$i18n.t("discount"), value: "discount", sortable: false },
        {
          text: this.$i18n.t("termination date"),
          value: "termination_date",
          sortable: false,
          hidden: !this.hasTerminationDate,
        },
      ].filter(function (col) {
        return col.hidden == null || !col.hidden;
      });
    },
    loading() {
      return (
        this.loadingIpSetup ||
        this.loadingDeprovision ||
        this.loadingSetPrimaryIp ||
        this.loadingCreate
      );
    },
    primaryIpChanged() {
      return (
        this.ipSetup != null &&
        this.primaryIp != null &&
        this.ipSetup.primary_ip != this.primaryIp
      );
    },
    possiblePrimaryIps() {
      var that = this;
      if (this.ipSetup != null)
        return this.ipSetup.possible_primary_ips.filter(function (ip) {
          return ip != that.ipSetup.primary_ip;
        });
      return [];
    },
    items() {
      var items = [];
      var staticIpMacs = {};
      if (this.ipSetup != null) {
        var items = this.ipSetup.static_ips.map(function (ip) {
          if (ip.mac) staticIpMacs[ip.ip] = ip;
          return {
            ...ip,
            network: ip.ip,
            isIp: true,
            isSelectable: !ip.read_only,
          };
        });
        items = items.concat(
          this.ipSetup.subnets.map(function (subnet) {
            return {
              ...subnet,
              isIp: false,
              isSelectable: !subnet.read_only,
            };
          })
        );
      }
      return items;
    },
    hasVia() {
      return (
        this.items.filter(function (item) {
          return item.via != null && item.via != "";
        }).length > 0
      );
    },
    hasMAC() {
      return (
        this.items.filter(function (item) {
          return item.mac != null && item.mac != "";
        }).length > 0
      );
    },
    hasTerminationDate() {
      return (
        this.items.filter(function (item) {
          return item.termination_date != null && item.terminationDate != "";
        }).length > 0
      );
    },
    terminationDateTime() {
      if (this.terminationDate) {
        const now = this.$moment();
        if (this.$moment(this.terminationDate).isSame(now, "day")) return null;
        else {
          return this.$moment(this.terminationDate)
            .set({
              hour: 0,
              minute: 0,
              second: 0,
            })
            .toISOString();
        }
      } else return null;
    },
    validCustomer() {
      return Boolean(
        (this.company || (this.firstName && this.lastName)) &&
          this.address.city &&
          this.address.zip &&
          this.address.street &&
          this.address.street_number &&
          this.phone &&
          this.email
      );
    },
  },
  methods: {
    getSetup() {
      var that = this;
      that.loadingIpSetup = true;
      this.$http
        .get("services/internet/" + this.product.id + "/ip-setup")
        .then((response) => {
          that.ipSetup = response.data;
          that.primaryIp = that.ipSetup.primary_ip;
        })
        .catch((error) => {
          if (error.invalid()) {
            that.showErrors(error.data);
          }
        })
        .finally(function () {
          that.loadingIpSetup = false;
        });
    },
    deprovision() {
      if (this.selected.length > 0) {
        var that = this;
        var selected = this.selected[0];
        that.loadingDeprovision = true;
        this.$http
          .delete("services/internet/" + this.product.id + "/ip-setup", {
            data: {
              ip: selected.isIp ? selected.network : null,
              subnet: selected.isIp ? null : selected.network,
              termination_date: this.terminationDateTime,
            },
          })
          .then((response) => {
            that.$snotify.success(that.$i18n.t("deprovisionSuccessMsg"));
            that.selected = [];
            that.getSetup();
          })
          .catch((error) => {
            if (error.invalid()) {
              that.$refs.obsDeprovision.setErrors(error.data);
              that.showErrors(that.$refs.obsDeprovision.getUnresolvedErrors(error));
            }
          })
          .finally(function () {
            that.loadingDeprovision = false;
          });
      }
    },
    setPrimaryIp() {
      if (this.primaryIpChanged) {
        var that = this;
        that.loadingSetPrimaryIp = true;
        this.$http
          .put("services/internet/" + this.product.id + "/ip-setup", {
            ip: this.primaryIp,
          })
          .then((response) => {
            that.$snotify.success(that.$i18n.t("setPrimaryIpSuccessMsg"));
            that.getSetup();
          })
          .catch((error) => {
            if (error.invalid()) {
              that.$refs.obsPrimaryIp.setErrors(error.data);
              that.showErrors(that.$refs.obsPrimaryIp.getUnresolvedErrors(error));
            }
          })
          .finally(function () {
            that.loadingSetPrimaryIp = false;
          });
      }
    },
    orderNewIps() {
      var that = this;
      this.loadingCreate = true;
      this.$http
        .post("services/internet/" + this.product.id + "/ip-setup", {
          prefix_length: this.prefixLength,
          ripe_netname: this.ripeNetname || null,
        })
        .then((response) => {
          that.$snotify.success(that.$i18n.t("orderNewIpsSuccessMsg"));
          that.getSetup();
        })
        .catch((error) => {
          console.log(">>> ", error)
          if (error.invalid()) {
            that.$refs.obsOrder.setErrors(error.data);
            that.showErrors(that.$refs.obsOrder.getUnresolvedErrors(error));
          }
        })
        .finally(function () {
          that.loadingCreate = false;
        });
    },
  },
  mounted() {
    this.getSetup();
  },
};
</script>

<i18n>
{
  "en": {
    "refresh": "refresh",
    "bbcs": "Swisscom",
    "alex": "Metronet",
    "IP/subnet deprovision": "IP/subnet termination",
    "primary IP change": "Primary IP change",
    "IP/subnet order": "IP/subnet order",
    "IP setup": "IP setup",
    "IP/subnet": "IP/Subnet",
    "MAC": "MAC",
    "via": "Via",
    "cancel": "Cancel",
    "deprovision": "Terminate",
    "termination date": "Termination date",
    "primary IP": "Primary IP",
    "new primary IP": "New primary IP",
    "change": "Change",
    "order": "Order",
    "prefix length": "Prefix length",
    "link identifier / OTO ID": "Link identifier / OTO ID",
    "create": "Create",
    "prefix-single": "/32 - {count} IP address",
    "prefix-subnet": "/{value} - {count} IP addresses",
    "RIPE netname": "RIPE netname",
    "deprovisionSuccessMsg": "termination successfully done",
    "setPrimaryIpSuccessMsg": "set primary IP successfully done",
    "orderNewIpsSuccessMsg": "order new IP/subnet successfully done",
    "network name for RIPE inetnum object": "network name for RIPE inetnum object (e.g. company name)"
  },
  "de": {
    "refresh": "aktualisieren",
    "bbcs": "Swisscom",
    "alex": "Stadtnetze",
    "IP/subnet deprovision": "IP/Subnetz kündigen",
    "primary IP change": "Primäre IP ändern",
    "IP/subnet order": "IP/Subnetz bestellen",
    "IP setup": "IP Einstellungen",
    "IP/subnet": "IP/Subnetz",
    "MAC": "MAC",
    "via": "Via",
    "cancel": "Abbrechen",
    "deprovision": "Kündigen",
    "termination date": "Kündigungsdatum",
    "primary IP": "Primäre IP",
    "new primary IP": "Neue primäre IP",
    "change": "Ändern",
    "order": "Bestellen",
    "prefix length": "Prefix Länge",
    "link identifier / OTO ID": "Link Identifikator / OTO ID",
    "create": "Anlegen",
    "prefix-single": "/32 - {count} IP Adressen",
    "prefix-subnet": "/{value} - {count} IP Adressen",
    "RIPE netname": "RIPE Netzname",
    "deprovisionSuccessMsg": "Kündigung wurde erfolgreich abgeschlossen",
    "setPrimaryIpSuccessMsg": "Primäre IP setzten wurde erfolgreich abgeschlossen",
    "orderNewIpsSuccessMsg": "Bestellung wurde erfolgreich abgeschlossen",
    "network name for RIPE inetnum object": "Netzwerk Name für RIPE inetnum Objekt (z.B. Firmenname)"
  }
}
</i18n>
